var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.6.2
(function () {
  'use strict';

  var __bind = function (fn, me) {
    return function () {
      return fn.apply(me, arguments);
    };
  };

  (function (root, factory) {
    if (exports) {
      return exports = factory();
    } else {
      return root.i18n = factory();
    }
  })(typeof self !== "undefined" && self !== null ? self : this || _global, function () {
    var Translator, i18n, translator;

    Translator = function () {
      function Translator() {
        (this || _global).translate = __bind((this || _global).translate, this || _global);
        (this || _global).data = {
          values: {},
          contexts: []
        };
        (this || _global).globalContext = {};
      }

      Translator.prototype.translate = function (text, defaultNumOrFormatting, numOrFormattingOrContext, formattingOrContext, context) {
        var defaultText, formatting, isObject, num;

        if (context == null) {
          context = (this || _global).globalContext;
        }

        isObject = function (obj) {
          var type;
          type = typeof obj;
          return type === "function" || type === "object" && !!obj;
        };

        if (isObject(defaultNumOrFormatting)) {
          defaultText = null;
          num = null;
          formatting = defaultNumOrFormatting;
          context = numOrFormattingOrContext || (this || _global).globalContext;
        } else {
          if (typeof defaultNumOrFormatting === "number") {
            defaultText = null;
            num = defaultNumOrFormatting;
            formatting = numOrFormattingOrContext;
            context = formattingOrContext || (this || _global).globalContext;
          } else {
            defaultText = defaultNumOrFormatting;

            if (typeof numOrFormattingOrContext === "number") {
              num = numOrFormattingOrContext;
              formatting = formattingOrContext;
              context = context;
            } else {
              num = null;
              formatting = numOrFormattingOrContext;
              context = formattingOrContext || (this || _global).globalContext;
            }
          }
        }

        if (isObject(text)) {
          if (isObject(text["i18n"])) {
            text = text["i18n"];
          }

          return this.translateHash(text, context);
        } else {
          return this.translateText(text, num, formatting, context, defaultText);
        }
      };

      Translator.prototype.add = function (d) {
        var c, k, v, _i, _len, _ref, _ref1, _results;

        if (d.values != null) {
          _ref = d.values;

          for (k in _ref) {
            v = _ref[k];
            (this || _global).data.values[k] = v;
          }
        }

        if (d.contexts != null) {
          _ref1 = d.contexts;
          _results = [];

          for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
            c = _ref1[_i];

            _results.push((this || _global).data.contexts.push(c));
          }

          return _results;
        }
      };

      Translator.prototype.setContext = function (key, value) {
        return (this || _global).globalContext[key] = value;
      };

      Translator.prototype.extend = function (ext) {
        return (this || _global).extension = ext;
      };

      Translator.prototype.clearContext = function (key) {
        return (this || _global).globalContext[key] = null;
      };

      Translator.prototype.reset = function () {
        this.resetData();
        return this.resetContext();
      };

      Translator.prototype.resetData = function () {
        return (this || _global).data = {
          values: {},
          contexts: []
        };
      };

      Translator.prototype.resetContext = function () {
        return (this || _global).globalContext = {};
      };

      Translator.prototype.translateHash = function (hash, context) {
        var k, v;

        for (k in hash) {
          v = hash[k];

          if (typeof v === "string") {
            hash[k] = this.translateText(v, null, null, context);
          }
        }

        return hash;
      };

      Translator.prototype.translateText = function (text, num, formatting, context, defaultText) {
        var contextData, result;

        if (context == null) {
          context = (this || _global).globalContext;
        }

        if ((this || _global).data == null) {
          return this.useOriginalText(defaultText || text, num, formatting);
        }

        contextData = this.getContextData((this || _global).data, context);

        if (contextData != null) {
          result = this.findTranslation(text, num, formatting, contextData.values, defaultText);
        }

        if (result == null) {
          result = this.findTranslation(text, num, formatting, (this || _global).data.values, defaultText);
        }

        if (result == null) {
          return this.useOriginalText(defaultText || text, num, formatting);
        }

        return result;
      };

      Translator.prototype.findTranslation = function (text, num, formatting, data, defaultText) {
        var a, b, c, d, e, result, triple, value, _i, _len;

        value = data[text];

        if (value == null) {
          return null;
        }

        if (typeof value === "object" && !Array.isArray(value)) {
          if ((this || _global).extension && typeof (this || _global).extension === "function") {
            value = this.extension(text, num, formatting, value);
            value = this.applyNumbers(value, num);
            return this.applyFormatting(value, num, formatting);
          } else {
            return this.useOriginalText(defaultText || text, num, formatting);
          }
        }

        if (num == null && !Array.isArray(value)) {
          if (typeof value === "string") {
            return this.applyFormatting(value, num, formatting);
          }
        } else {
          if (value instanceof Array || value.length) {
            a = num === null;

            for (_i = 0, _len = value.length; _i < _len; _i++) {
              triple = value[_i];
              b = triple[0] === null;
              c = triple[1] === null;
              d = num >= triple[0];
              e = num <= triple[1];

              if (a && b && c || !a && (!b && d && (c || e) || b && !c && e)) {
                result = this.applyFormatting(triple[2].replace("-%n", String(-num)), num, formatting);
                return this.applyFormatting(result.replace("%n", String(num)), num, formatting);
              }
            }
          }
        }

        return null;
      };

      Translator.prototype.applyNumbers = function (str, num) {
        str = str.replace("-%n", String(-num));
        str = str.replace("%n", String(num));
        return str;
      };

      Translator.prototype.getContextData = function (data, context) {
        var c, equal, key, value, _i, _len, _ref, _ref1;

        if (data.contexts == null) {
          return null;
        }

        _ref = data.contexts;

        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          c = _ref[_i];
          equal = true;
          _ref1 = c.matches;

          for (key in _ref1) {
            value = _ref1[key];
            equal = equal && value === context[key];
          }

          if (equal) {
            return c;
          }
        }

        return null;
      };

      Translator.prototype.useOriginalText = function (text, num, formatting) {
        if (num == null) {
          return this.applyFormatting(text, num, formatting);
        }

        return this.applyFormatting(text.replace("%n", String(num)), num, formatting);
      };

      Translator.prototype.applyFormatting = function (text, num, formatting) {
        var ind, regex;

        for (ind in formatting) {
          regex = new RegExp("%{" + ind + "}", "g");
          text = text.replace(regex, formatting[ind]);
        }

        return text;
      };

      return Translator;
    }();

    translator = new Translator();
    i18n = translator.translate;
    i18n.translator = translator;

    i18n.create = function (data) {
      var trans;
      trans = new Translator();

      if (data != null) {
        trans.add(data);
      }

      trans.translate.create = i18n.create;
      trans.translate.translator = trans;
      return trans.translate;
    };

    return i18n;
  });
}).call(exports);
export default exports;